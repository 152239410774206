<template>
  <div class="app-container holland-test">
    <div class="title">
      <h3 class="first-title">性格与选科选专业</h3>
      <h4 class="second-title">霍兰德职业测试</h4>
    </div>
    <div class="main">
      <div class="left">
        <img src="@/assets/test/holland/holland-picture1.jpg" />
      </div>
      <div class="right">
        <p>本测试依据霍兰德SDS职业兴趣测试模型，按照2015版《中华人民共和国职业分类大典》的职业分类，结合新高考条件和我国高中生现实情况研究而成，仅供考生和家长参考。
        </p>

        <p>霍兰德职业适应性测验(The Self-Directed Search，简称SDS)由美国著名职业指导专家J.霍兰德（Holland）编制。在几十年间经过一百多次大规模的实验研究，形成了人格类型与职业类型的学说和测验。
          霍兰德在其一系列关于人格与职业关系的假设的基础上，提出了六种基本的职业类型，即：实际型、研究型（调研型）、艺术型、社会型、企业型（事业型）、传统型（常规型）。该测验能帮助被试者发现和确定自己的职业兴趣和能力专长,
          从而科学地做出求职择业。
        </p>

        <p>霍兰德职业兴趣SDS测试一般是适合于高中生，通过此测试可以让高中生确定自己的兴趣爱好，给大学的专业选择提供参考。
        </p>

        <p>
          人格和职业有着密切的关系，不同职业对从业者的人格特征的要求是有差距的，如果通过科学的测试，可以预知自己的人格特征，这有助于选择适合于个人发展的职业，进行职业倾向测试，可以帮助您作一次简单的人格自评，从而更加清楚自己的人格特征更适合从事哪方面的工作。
        </p>

        <p>需要指出的是，志愿选择会受各种因素影响，例如录取分数、招生数量、就业前景、发展趋势等，测试结果不代表你只能选择测试分析的专业，而应根据个人自身情况综合考虑。
        </p>
      </div>
    </div>
    <div class="start">
      <el-button class="start-btn" type="primary" @click="startTest">开始测评</el-button>
    </div>

  </div>
</template>

<script>
import { getStore } from '@/utils/utils'
export default {
  name: "hollandTest-index",
  data() {
    return {

    }
  },
  computed: {

  },
  created() {

  },
  mounted() {

  },
  methods: {
    startTest() {
      // let temp = getStore('LoginData')
      // if(temp){
      //   this.$router.push('/test-content?testType=2')
      // }else{
      //   this.$message({
      //     type: 'error',
      //     message: '请先登录！',
      //     showClose: true,
      //     center: true
      //   })
      //   this.$router.push('/login')
      // }

      this.$store.dispatch('user/judgeLoginStatus').then(flag => {
        if (flag) {
          this.$router.push('/test-content?testType=2')
        } else {
          this.$message({
            type: 'info',
            message: '请先登录！',
            showClose: true,
            center: true
          })
          this.$router.push('/login')
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.holland-test {
  padding-top: 2rem;

  .title {
    text-align: center;

    .first-title {
      font-size: 30px;
      color: rgba(var(--themecolor),0.75);
      margin-bottom: 1rem;
    }

    .second-title {
      font-size: 20px;
      margin-top: 1rem;
      color: #999999;
    }

  }

  .main {

    .left {
      float: left;
    }

    .right {}
  }

  .start {
    text-align: center;
    padding: 2rem 0;

    .start-btn {
      width: 8rem;
    }
  }
}
</style>
<style lang="less">
.start .el-button--primary:hover{
  background: rgba(var(--themecolor),1);
  border-color: rgba(var(--themecolor),1);
}
.start .el-button--primary,.start .el-button--primary:focus{
  background: rgba(var(--themecolor),0.75);
  border-color: rgba(var(--themecolor),0.75);
}
</style>